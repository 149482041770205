.top-nav {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(10px);
  background-color: rgba(29, 29, 29, 0.7);
}

.top-nav-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-inline: 30px;
  margin-top: 0px;
  max-width: 1300px;
  flex-grow: 1;
  border-bottom: 1px solid rgb(19, 19, 19);
  border-top: 1px solid rgba(0, 0, 0, 0.7);
}

.top-nav-items {
  display: flex;
}

.top-nav-item {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  width: 60px;
  text-align: center;
  line-height: 20px;
  transition: background-color 0.2s, transform 0.2s;
}

.top-nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.1);
}

.link {
  color: #fffcec;
}

.menu-dropdown {
  height: 50px;
  width: 50px;
  display: none; /* Hide by default */
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: #2a3b4900;
  border-radius: 15px;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: height 0.7s ease, width 0.7s ease, background-color 0.7s ease;
  z-index: 1000;
}

.dropbtn {
  color: white;
  background: none;
  border: none;
  padding: 10px;
  padding-right: 11px;
  font-size: 1.5rem;
  cursor: pointer;
  outline: inherit;
}

.menu-content {
  background: transparent;
  color: white;
  opacity: 0;
  max-height: 300px;
  visibility: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease, visibility 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 160px;
  border-radius: 5px;
  padding: 20px;
  padding-top: 60px;
  z-index: 1000;
}

.menu-dropdown.menu-active {
  height: 300px;
  width: 200px;
  border-radius: 5px;
  background-color: #1f1f1f;
  border: 1px solid rgb(85, 85, 85);
}

.menu-dropdown.menu-active .menu-content {
  visibility: visible;
  opacity: 1;
  max-height: 300px;
  pointer-events: auto;
  transition-delay: 0.3s;
}

.menu-content ul {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  font-size: 1.2rem;
  padding: 10px 0;
  transition: color 0.5s ease;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .top-nav-items {
    display: none;
  }

  .menu-dropdown {
    display: flex; /* Ensures the dropdown icon is visible on small screens */
  }
}

