
 .modal {
  position: fixed;
  left: 0;
  top: 70px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
 }
 .modal-content {
  width: 900px;
  max-width: 80%;
  height: 80%;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.459);
  background: linear-gradient(110.6deg, rgb(38, 47, 53) -18.3%, rgb(28, 36, 39) 16.4%, rgb(26, 30, 37) 68.2%, rgb(17, 19, 27) 99.1%);;
  border-radius: 15px;
  padding:20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  scrollbar-color: rgb(109, 109, 109) rgba(255, 255, 255, 0);
  border: 1px solid rgb(82, 82, 82);
 }
