.home-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  text-decoration: none;
}

.fade-in-section {
  opacity: 0;
  transform: translatex(-30vw);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 1000ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  max-width: 1300px;
  flex-grow: 1;
}

.home-blurb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 60px;
  margin-top: 170px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(27, 27, 27) ;
  border-radius: 10px;
}

.logo-cont {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.logo-img-top {
  width:200px;
  height:200px;
  filter: brightness(90%) sepia(10%);
}

@media only screen and (max-width: 700px) {
  .logo-img-top {
    width:150px;
    height:150px;
  }
}


.logo-img {
  width: 300px;
  height: 300px;
  filter: brightness(90%) sepia(10%);
}
@media only screen and (max-width: 1100px) {
  .logo-img {
    display: none;
  }
  .logo-cont {
    display: flex;
  }
}

@media only screen and (max-width: 1100px) {
  .home-blurb {
    margin-top: 100px;
  }
}


@media only screen and (max-width: 900px) {
  .home-blurb {
    font-size: 45px;
    margin-top: 100px;
  }
}




.home-socials {
  display: flex;
  justify-content: space-between;
  width: 250px;
  padding-bottom: 20px;
  margin-bottom: 150px;
}

@media only screen and (max-width: 700px) {
  .home-blurb {
    font-size: 30px;
  }
  .home-socials {
    margin-bottom: 75px;
  }
}

@media only screen and (max-width: 500px) {
  .home-blurb {
    font-size: 20px;
  }
}


.social {
  border-radius: 5px;
  padding-block: 5px;
  padding-inline: 10px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(27, 27, 27) ;
  transition: 0.2s;
}

.social:hover {

  background-color: rgba(255, 255, 255, 0.1);

}

.about-para {
  padding-block: 10px;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 150%;
}

.about-cont {
  margin-bottom: 150px;
}

.about-content {
  display: flex;
  align-items: center;
}

.portrait {
 display: flex;
 border-radius: 10px;

 width: 26%;
 padding-right: 40px;
 aspect-ratio: 1 / 1;

 object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .about-cont {
    display: flex;
    flex-direction: column;
  }
  .about-content {
    flex-direction: column;
    align-items: flex-start;
  }
  .portrait {
    width: 50%;
    padding-right: 0;
    margin-bottom: 20px; /* Add margin to separate the image from the text */
  }
}

@media only screen and (max-width: 700px) {
  .about-para {
    font-size: 16px;
  }
}

.skills {
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 150px;
   justify-content: space-between;
}

.skill {
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  width: 90px
}

.icon {
  width: 70px; /* Set the width */
  height: 70px; /* Set the height */
}

@media only screen and (max-width: 700px) {
  .skill {
    padding: 30px;
    font-size: 15px;
  }

  .icon {
    width:50px;
    height: 50px;
  }
}


.footer {
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  padding-block: 15px;
  letter-spacing: 0.8px;

  width: 100%;
}

#work {
  scroll-margin-top: 90px;
}

#about {
  scroll-margin-top: 90px;
}

#contact {
  scroll-margin-top: 90px;
}

#skills {
  scroll-margin-top: 90px;
}

.contact-blurb {
  font-size: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}

/* Text Animation */
.home-blurb .type {
  padding: 2rem 1rem;
  width: auto;
}

.blurb1,
.blurb2,
.blurb3,
.blurb4 {
  width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  visibility: hidden;
  animation: show 0s forwards, typing 0.5s steps(20, end) forwards, fadein 1s forwards;
}

.blurb2 {
  animation-delay: 0.2s, 0.2s, 0.2s;
}

.blurb3 {
  animation-delay: 0.6s, 0.6s, 0.6s;
}

.blurb4 {
  animation-delay: 0.9s, 0.9s, 0.9s;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 20ch;
  }
}

@keyframes show {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text Animation End*/