.projects-cont {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 150px;
}

.project {
  min-width: 450px;
  max-width: 700px;
  height: 600px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 450px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.24);
  border: 1px solid rgb(27, 27, 27);
  margin: 20px;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s;
}




.project-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
}

.project-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(60%);
  position: absolute;
  transition: 0.9s;
}




.project-title {
  text-align: center;
  position: absolute;
  font-size: 50px;
  width: 450px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  transition: .9s;
}

.project:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  .project-title {
    color: black;
  }
  .project-img {
    filter: brightness(80%) opacity(70%);
  }
}

@media only screen and (max-width: 700px) {
  .project {
    min-width: 300px;
    flex-basis: 300px;
    height: 300px;
  }

  .project-img {
    height: 300px;
  }

  .project-title {
    font-size: 24px;
    width: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .project {
    min-width: 200px;
    flex-basis: 200px;
  }
}

.modal-img {
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
  height: 50vh;
}

.modal-img-sub {
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
  height: 400px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.719);
  margin-bottom: 20px;
  transition: 0.3s;
}

.modal-img-sub:hover {
  filter: brightness(65%);
}

.additional-img {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 900px) {
  .additional-img {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.sub-img {
  width: 48%;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .sub-img {
    width: 100%;
  }
}

.github-logo {
  object-fit: cover;
  height: 15px;
  padding-right: 5px;
}

.modal-links {
  padding-top: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(255, 255, 255);

}

.modal-link {
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-decoration: none;
  margin-right: 15px;
}

.modal-link:hover {
  border-bottom: 2px solid white;
}

.modal-title {
  font-size: 30px;
  margin-top: 10px;
}

.modal-skills {
  display: flex;
  margin-block: 10px;
  flex-wrap: wrap;
}

.modal-skill {
  border-radius: 15px;
  padding-inline: 10px;
  padding-block: 5px;
  color: rgb(255, 255, 255);
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.103);
  margin-block: 10px;
  margin-right: 20px;
}

.modal-desc {
  font-size: 20px;
  color: rgb(212, 212, 212);
}

@media only screen and (max-width: 900px) {
  .modal-desc {
    font-size: 16px;
  }
}
