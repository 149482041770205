.lightbox {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.877);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
 }

 .lightbox-content {
  display: flex;
  justify-content: center;
  width: 90vw;
  height: 90vh;
  align-items: center;
 }

 .image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  max-width: 90vw;
  max-height: 90vh;
}

 .lightbox-img {
  object-fit: contain;
  max-width: 80vw;
  max-height: 90vh;
  border: 1px solid white;
 }

 .prev-icon {
  font-size: 30px;
  padding: 20px;
  cursor: pointer;
 }

 .next-icon {
  font-size: 30px;
  padding: 20px;
  cursor: pointer;
 }

 @media only screen and (max-width: 700px) {
  .lightbox-img {
    max-width: 75vw;
  }
  .prev-icon {
    padding-right: 10px;
   }
   .next-icon {
    padding-left: 10px;
   }
}